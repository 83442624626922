import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';


import { highlightFilter, GetCurrentDateFromArray } from '../../CommonComponents'
import { getRandomNumber, isToday, isYesterday, isValidDate } from '../../CommonFunctions';
import './Skin.css'
import MiContexto from '../../MiContexto'
import { use } from 'i18next';
import { useInsertionEffect } from 'react';
import moment from 'moment';
import { ms } from 'date-fns/locale';


export function WpChats(props) {

    const history = useHistory();

    const { user } = useContext(MiContexto)
    const { chats, handleChatChange, deleteChat, totalObjectsUnread, lastUpdate } = props


    // const wppAbout = "about"
    // const profilePic = "https://www.w3schools.com/howto/img_avatar.png"


    const getUnreadColor = (unread) => {
        if (unread) return "#03d562"
        return "#4c4f50"
    }

    const getCircularReveal = (unread) => {
        if (!parseInt(unread) >= 1) return null
        return (
            <div className='ml-2 font-weight-bold bg-light mt-1' style={{ color: "#008069", borderRadius: "50%", width: "13px", height: "13px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "8px" }}>{unread}</span>
            </div>
        )
    }

    const TypeStyle = (props) => {
        const { type, isUnread } = props
        if (isUnread) return null

        //Entregado
        if (type === 1 || type === 2) return <span className='text-secondary' style={{ fontSize: "10px" }}><i className='fa fa-check ' /><i className='fa fa-check' /></span>
        //Entregado y LEIDO
        if (type === 3 || type === 4) return <span className=' text-primary ' style={{ fontSize: "10px" }}><i className='fa fa-check' /><i className='fa fa-check' /></span>

        return null
    }



    return (
        <div>

            {/* CABEZERA CHAT */}
            <div className='w-100 rounded-top' style={{ backgroundColor: "#008069" }} >
                <div className='d-flex justify-content-between px-1 py-3'>
                    <div className='text-left ml-1'>
                        {/* <img src={profilePic} className="rounded-circle" style={{ width: "35px", height: "35px" }} /> */}
                        <div className='d-flex ' style={{ fontSize: "16x" }}>
                            <i className='fa fa-whatsapp mx-1' style={{ fontSize: "25px" }} />
                            <span className='font-weight-bold'>WhatsApp</span>
                        </div>
                        <div className='small ml-4 font-italic' style={{ lineHeight: "7px", fontSize: "10px" }}>UPDATE: <Moment date={lastUpdate} format="dddd Do, MMM, HH:mm A" /></div>
                        {/* <div className='small ml-1'>{wppAbout}</div> */}
                    </div>
                    <div className='d-flex justify-content-between '>
                        <i className='fa fa-camera mx-3' />
                        <i className='fa fa-search' />
                        <i className='fa fa-ellipsis-v mx-3' />
                    </div>
                </div>
                <div className='d-flex justify-content-between px-3 small font-weight-bold pb-1'>
                    <div className='d-flex' style={{ borderBottom: "3px solid #fff" }}><i style={{ fontSize: "16px" }} className='fa fa-users mx-2' /> Chats  {getCircularReveal(totalObjectsUnread)}  </div>
                    <div className='d-flex' style={{ fontSize: "12px" }}>Estados{getCircularReveal(getRandomNumber(0, 0))}</div>
                    <div className='d-flex' style={{ fontSize: "12px" }}>Llamadas {getCircularReveal(getRandomNumber(0, 3))}</div>
                </div>
            </div>
            {/* *********************** */}

            <ul className="list-group list-group-flush rounded-bottom bg-light" style={{ height: "100%" , paddingBottom:"20px", overflowX: "hidden" }}>
                {chats.map((chat, index) => {

                    //------
                    let chatID = chat.chatID
                    let nombre = chat.nombreContacto

                    let cantMessages = chat.cantMessages

                    let cantMessagesUnread = chat.cantMessagesUnread
                    if (cantMessagesUnread == true) cantMessagesUnread = 1

                    if (user.isDemoUsr) cantMessagesUnread = getRandomNumber(0, 15)

                    let snipet = chat.snipet
                    let fecha = chat.fecha
                    let hora = chat.hora

                    let timestamp = chat.timestamp//La recibo en wtspweb
                    if (timestamp) {
                        fecha = moment.unix(timestamp).format("YYYY-MM-DD")
                        hora = moment.unix(timestamp).format("HH:mm:ss")
                    }
                    //-------

                    if (!chat.picture) chat.picture = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"

                    let blur = ""
                    if (chat.isDummy) blur = "divBlur"

                    return (
                        <div key={index} className={`d-flex justify-content-between my-2 mx-1 ${blur}`} onClick={() => { if (!chat.isDummy) { handleChatChange(chatID) } else { history.push('/buy') } }} >
                            <div className='d-flex '>

                                <img src={chat.picture} className="rounded-circle " style={{ width: "35px", height: "35px", minWidth: '35px' }} />
                                <div className='small'>
                                    <div className='mx-2 font-weight-bold text-dark'>{nombre} </div>
                                    <div className='mx-2 ' style={{ color: "#4c4f50" }}><TypeStyle type={chat.type} isUnread={chat.unread} /> {snipet?.substring(0, 50)}</div>
                                </div>
                            </div>

                            <div className='small ' >

                                <div className='small text-center' style={{ color: getUnreadColor(chat.unread) }}>
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                </div>

                                {/* <i className=" ml-2 fa fa-trash-o  text-danger " onClick={() => deleteChat(nombre)} /> */}

                                <div className='d-flex justify-content-center mt-0 '>

                                    {/* <div className=' ml-2 font-weight-bold' style={{ color: "#fff", backgroundColor: "#03d562", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {cantMessages}
                                    </div> */}

                                    {cantMessagesUnread > 0 ?
                                        <div className=' ml-2 font-weight-bold' style={{ color: "#fff", backgroundColor: "#03d562", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {cantMessagesUnread}
                                        </div>
                                        :
                                        <div className=' ml-2 font-weight-bold' style={{ color: "#008069", backgroundColor: "#f8f9fa", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {cantMessages}
                                        </div>
                                    }
                                </div>

                                {/* {cantMessagesUnread > 0 &&
                                    <div className='text-danger ' ><span className='badge badge-danger pt-1'>{cantMessagesUnread} Nuevos</span> <span ></span></div>
                                } */}

                            </div>

                        </div>
                    )

                })}
            </ul>


        </div>



    )
}


const ShowItemDate = (props) => {

    const { fecha, hora } = props

    if (isValidDate(fecha + " " + hora)) {
        if (isToday(fecha)) {
            return (<React.Fragment> <span className="mr-1"></span> <Moment date={fecha + " " + hora} format=" HH:mm" /> </React.Fragment>)
        } else if (isYesterday(fecha)) {
            return (<React.Fragment> <span className="mr-1"></span> Ayer <Moment date={fecha + " " + hora} format=" HH:mm" /></React.Fragment>)
        } else {
            return (<Moment date={fecha + " " + hora} format="L" />)
        }
    } else {
        return (fecha + " " + hora)
    }

}


const openBase64Image = (base64) => {
    var win = window.open();
    win.document.write('<img src="data:image/jpeg;base64,' + base64 + '"/>');
}


export function WpConversation(props) {

    const { messages, filter, featureGranted, deleteChat, deleteObject ,chat} = props
    const history = useHistory();

    useEffect(() => {
        var objDiv = document.getElementById("wppConversation");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, [messages])



    if (!messages || messages.length === 0) return null


    let wppBackground = './images/wppBackground.png'
    let chatPicture = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"

    let contact = messages[0]//tomo uno cualquiera
    let cantMessaes = messages.length

    let nombreContacto = contact.nombreContacto
    if (!nombreContacto) nombreContacto = contact.numero

    let unReadMessages = messages.filter(msg => msg.unRead === true).length

    let timestamp = null

    if(chat){
        nombreContacto = chat.nombreContacto
        if(chat.picture) chatPicture = chat.picture
        unReadMessages = chat.cantMessagesUnread
        timestamp = chat.timestamp
    }


    return (
        <div className='mb-4'>

            {/* CABEZERA CONVERSACION */}
            <div>
                <div className='w-100 rounded-top py-3 ' style={{ backgroundColor: "#008069" }}>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'  style={{alignItems:"center"}}>
                            <i className='fa fa-arrow-left mx-2 pt-2' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                            <img src={chatPicture} className="rounded-circle " style={{ width: "30px", height: "30px", minWidth: '30px' }} />
                            <div style={{ marginTop: "-6px" }}>
                                <div className='text-light ml-2 pt-1 mb-1' style={{ fontSize: "0.8em", fontWeight:"700" }}>{nombreContacto}</div>
                                <div className='small ml-2' style={{ lineHeight: "6px" , fontSize: "0.7em"}} >
                                    {cantMessaes} Messages
                                    {unReadMessages > 0 && <span className='badge ml-1 ' style={{ backgroundColor: "#03d562"}} >{unReadMessages} Nuevos</span>}
                                </div>
                            </div>


                        </div>
                        <div className='d-flex'>
                            <i className='fa fa-video mx-3' />
                            <i className='fa fa-phone' />
                            {/* <i className='fa fa-ellipsis-v mx-3' /> */}
                            <i className='text-danger fa fa-trash mx-3' onClick={() => deleteChat(nombreContacto)} />
                        </div>
                    </div>

                </div>
            </div>
            {/* ******************* */}


            <div id="wppConversation" className='text-dark py-2 px-2 rounded-bottom' style={{
                backgroundImage: `url(${wppBackground})`, height: "500px", overflowX: "hidden",
                backgroundAttachment: "fixed", backgroundSize: "100%"
            }}>


                {messages.map((msg, index) => {

                    //------
                    let {key, texto, fecha, hora,type} = msg


                    //let tipo = msg.tipo
                    let tipo = getRandomNumber(0, 1) === 0 ? "sent" : "received"
                    let unRead = msg.unRead
                    //-------


                    let timestamp = msg.timestamp//La recibo en wtspweb
                    if (timestamp) {
                        fecha = moment.unix(timestamp).format("YYYY-MM-DD")
                        hora = moment.unix(timestamp).format("HH:mm:ss")
                    }
                    let fromMe = msg.fromMe//La recibo en wtspweb
                    if (fromMe) tipo = "sent"
                    else tipo = "received"


                    let isCall = false
                    if (texto.includes("Llamada") || texto.includes("llamadas") || texto.includes("Llamando") || texto.includes("Call") || texto.includes("calls") || texto.includes("Calling")) isCall = true


                    let boxType = "box1 sb1"
                    if (tipo === "sent") boxType = "box2 sb2"

                    let blur = ""
                    if (!featureGranted) blur = "divBlur"

                   let typeContent=null
                    // if(type==="image") typeContent=<i className='fa fa-image'/>
                    // if(type==="ptt") typeContent=<i className='fa fa-microphone'/>
                    // if(type==="sticker") typeContent=<i className='fa fa-sticky-note'/>
                    if(type==="video") typeContent=<i style={{fontSize:'24px'}} className ='fa fa-video'/>
                    if(type==="video" && msg.isGif) typeContent="GIF"
                    if(type==="document") typeContent=<i style={{fontSize:'24px'}} className='fa fa-file'/>
                    if(type==="location") typeContent=<i style={{fontSize:'24px'}} className='fa fa-map-marker'/>
                    if(type==="contact") typeContent=<i style={{fontSize:'24px'}} className='fa fa-user'/>
                    if(type==="voice") typeContent=<i style={{fontSize:'24px'}} className='fa fa-phone'/>
                    if(type==="revoked")  typeContent=<span style={{fontSize:'15px'}} className='text-secondary small font-italic'>Mensaje eliminado</span>

                    let aImage=null
                    if(type==="image" || type==="sticker") aImage=msg.aMedia?.data

                    let aAudio=null
                    if(type==="ptt") aAudio=msg.aMedia?.data



                    return (
                        <div key={index} className={`my-2 ${blur}`}>
                            <div className='d-flex justify-content-center'>
                                <GetCurrentDateFromArray array={messages} index={index} itemName="msg" innerStyle={{ maxWidth: "500px", fontSize: "0.8em", backgroundColor: "#DBF3FB", color: "#3b444d", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", marginTop: "15px", borderRadius: "6px" }} />
                            </div>

                            {unRead && <span className='badge ml-1 text-light' style={{ backgroundColor: "#03d562" }} >Nuevo</span>}
                            <div className={`${boxType} small my-0 p-1 `}>
                                <div style={{ color: "#000", fontWeight: 600, marginLeft: "3px",wordWrap: 'break-word' }}>
                                    {isCall && <span style={{ fontSize: "10px", marginRight: "5px" }}><i style={{ fontSize: "25px", color: "#075E54" }} className='fa fa-phone-square' /></span>}
                                    {highlightFilter(texto, filter)}
                                    <div className='text-danger text-center mt-2' >{typeContent}</div>
                                    {aImage&&<img src={`data:image/jpeg;base64,${aImage}`} style={{maxWidth:'200px',maxHeight:'200px'}} onClick={()=>openBase64Image(aImage)} />}
                                    {aAudio&&<audio controls src={`data:audio/mp3;base64,${aAudio}`}/>}
                                </div>
                                <div className='small text-secondary text-right'>
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                    {(tipo === "sent") && <span style={{ fontSize: "7px", marginLeft: "5px" }}><i className='fa fa-check' /><i className='fa fa-check' /></span>}
                                </div>
                            </div>
                        </div>
                    )
                })}



            </div>
            {!featureGranted &&
                <button className='btn btn-danger btn-block btn-sm' style={{ width: "100%", zIndex: "1", height: "37px" }}
                    onClick={() => history.push('/buy')}>Desbloquear mensajes <i className='fa fa-eye ml-1' /></button>
            }
        </div>
    )
}



