import React, { Component, useState } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---
import { HashRouter, Link, useHistory } from "react-router-dom";

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { MyLoader, ShowFiltersResult, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, checkFileExistsFromFireStorage, deleteFileFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, fileExistsOnArray } from '../CommonFunctions'


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


import ReactPlayer from 'react-player'

//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';
import { random } from 'lodash';

import { Player } from '@lottiefiles/react-lottie-player';

const FEATURE_ID = 28
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

class WtspAudios extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            wtspAudios: null,
            wtspAudiosFiltered: [],
            wtspAudiosOrderRecentFirst: true,

            filterDate: null,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,


            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
        //CREO el puntero hacia el nuevo imei

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDwtspAudios")

        return miFireBase.database().ref(this.context.dbPath).child("MDwtspAudios")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****wtspAudios dbRef.once VALUE****" + this.context.activeDevice.imei)

            let wtspAudiosAux = []
            let callsGroupAux = []


            snap.forEach((snap) => {

                const audio = ({
                    key: snap.key,

                    nombre: snap.val().nombreContacto,
                    numero: snap.val().numero,
                    duration: snap.val().duration,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    app: snap.val().app,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                checkFileExistsFromFireStorage(miFireBase, audio, this.deleteObject)

                if (!fileExistsOnArray(audio, wtspAudiosAux)) {//Como es acumulativo, debo chequear
                    wtspAudiosAux.push(audio)
                } else {
                    this.deleteObject(audio.key)
                }

                this.addCallToGroup(audio, callsGroupAux)



            })
            sortArrayByFechaHora(wtspAudiosAux)//solo se hace al principio
            sortArrayByFechaHora(callsGroupAux)


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = wtspAudiosAux[wtspAudiosAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(wtspAudiosAux, this.state.wtspAudiosOrderRecentFirst)

            this.setState({
                wtspAudios: wtspAudiosAux,
                wtspAudiosFiltered: wtspAudiosAux,

                lastUpdate: lastUpdate,
                totalObjects: wtspAudiosAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })

    }


    addCallToGroup = (audio, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)

        let pos = 0
        if (audio.nombre != "desconocido") {
            pos = currentGroup.map((s) => s.nombre).indexOf(audio.nombre)
        } else {
            pos = currentGroup.map((s) => s.numero).indexOf(audio.numero)
        }

        if (pos < 0) {
            //Creo un nuevo elemento en el chat
            currentGroup.push({
                nombre: audio.nombre,
                numero: audio.numero,
                cantCalls: 1,
                fecha: audio.fecha,
                hora: audio.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cantCalls += 1
            currentGroup[pos].fecha = audio.fecha
            currentGroup[pos].hora = audio.hora
        }
        //----------------------------------

    }



    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.wtspAudios
            const filteredArray = this.state.wtspAudiosFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    wtspAudios: originalArray,
                    wtspAudiosFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }

        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {

        if (date) {

            let callsRecordingFilteredAux = []

            this.state.wtspAudios.map(audio => {
                if (audio.fecha == format(date, 'yyyy-MM-dd'))
                    callsRecordingFilteredAux.push(audio)
            })


            sortArrayByRecentFirst(callsRecordingFilteredAux, this.state.wtspAudiosOrderRecentFirst)

            this.setState({
                filterDate: date,
                wtspAudiosFiltered: callsRecordingFilteredAux
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({
            wtspAudiosFiltered: this.state.wtspAudiosFiltered.reverse(),
            wtspAudiosOrderRecentFirst: !this.state.wtspAudiosOrderRecentFirst
        })
    }



    handleShowAllClick = () => {

        let wtspAudiosAux = this.state.wtspAudios//vuelvo a todos
        sortArrayByRecentFirst(wtspAudiosAux, this.state.wtspAudiosOrderRecentFirst)

        this.setState({
            wtspAudiosFiltered: wtspAudiosAux,
            filterDate: null,
        })
    }




    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.wtspAudios) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('WtspAudios.title')}</h3>
                        <MyLoader text={t('WtspAudios.loading')} />
                    </div>
                </div>
            )


        } else {

            if (this.state.wtspAudios.length == 0) return <ShowNoItemsYet size={this.state.wtspAudios.length} itemName="Whatsapp audios" />



            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('WtspAudios.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title="audios" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ wtspAudios: [], wtspAudiosFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark  rounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.wtspAudios} />
                        </div>

                        <ButtonShowAll size1={this.state.wtspAudios.length} size2={this.state.wtspAudiosFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.wtspAudiosFiltered.length} filterName="date" showAll={this.handleShowAllClick} />

                    <div className="row">

                        <div className="col-12 mb-3">
                            {/* WHATSAPP AUDIOS-------- */}


                            <ButtonOrderFirst length={this.state.wtspAudiosFiltered.length} order={this.state.wtspAudiosOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            {/* audios */}
                            <ul className="list-group list-group-flush" >
                                {this.state.wtspAudiosFiltered.slice(0, this.state.renderSize).map((audio, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.wtspAudiosFiltered} index={index} itemName="audios" />
                                            <ShowAudio
                                                audio={audio}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.wtspAudiosFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.wtspAudiosFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )

        }
    }
}
WtspAudios.contextType = MiContexto
WtspAudios = withTranslation()(WtspAudios);
export default withRouter(WtspAudios);




function ShowAudio(props) {

    const { t } = useTranslation();
    let history = useHistory();

    const [showControls, setShowControls] = useState(false)
    const [autoPlay, setAutoPlay] = useState(true)

    let { audio, featureGranted } = props
    //------
    let key = audio.key
    let url = audio.url

    let duration = audio.duration
    let fecha = audio.fecha
    let hora = audio.hora
    let unRead = audio.unRead
    //-------

    // if(!duration) duration=random(10, 60)


    const handlePlayClick = (value) => {
        if (featureGranted) {
            setShowControls(value)
        } else {
            alert(t('Buy.buyToListen'))
            history.push("/buy");
        }
    }

    // if (featureGranted) {
    //SE MUESTRA
    return (

        <Bounce >
            <li className={"list-group-item bg-dark py-1"}>
                <div className="row pt-1">
                    <div className="col-12">

                        {unRead && <span className="badge badge-danger">{t('WtspAudios.new')}</span>}


                        {showControls ?
                            <div className="d-flex justify-content-center mb-2">
                                <ReactPlayer
                                    playing={autoPlay}
                                    url={url}
                                    controls={true}
                                    height='50px'
                                    volume={1}
                                    onPause={() => setAutoPlay(false)} onEnded={() => setAutoPlay(false)}
                                />
                            </div>
                            :
                            <div className="d-flex">
                                <button className="btn btn-success btn-sm mt-1 mr-2" style={{ width: "125px" }} onClick={() => handlePlayClick(true)}> <i className="fa fa-play mr-1" /> {t('WtspAudios.play')}</button>
                                {duration && <div className="mt-3 small text-success"><i className="fa fa-hourglass-end fa-1x " /> {duration} {t('MicRecorder.seconds')}</div>}
                            </div>

                        }


                    </div>

                    <div className="col-12">
                        <div className="text-secondary small d-flex justify-content-end">
                            <ShowItemDate fecha={fecha} hora={hora} />
                            <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => { props.deleteObject(key); handlePlayClick(false); setAutoPlay(true) }} />
                        </div>
                    </div>

                </div>
            </li>
        </Bounce >
    )


}
