import React, { Component, useEffect, useState } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---
import { HashRouter, Link, useHistory } from "react-router-dom";

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import { functionGetRandomRealTimeData, sendPushMessageToDevice } from '../CloudFunctions'
import miFireBase from '../config/config'


import { MyLoader, ShowFiltersResult, AlertMessage, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { playAudio, resizeNavBar, checkFileExistsFromFireStorage, deleteFileFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, getRandomNumber } from '../CommonFunctions'


import { Player } from '@lottiefiles/react-lottie-player';
//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';


//Calculos con fechas
import moment from 'moment';
import { format, isThisSecond } from "date-fns";

import ReactPlayer from 'react-player'

//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 11
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

const MAX_TIMEOUT_MS = 10000


class MicRecorder extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            micRecordings: null,
            micRecordingsFiltered: [],
            micRecordingsOrderRecentFirst: true,

            filterDate: null,


            fbTrash: [],//elementos que debo eliminar de firebase al salir (solo en realtime)

            autoMicRecorder: false,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            alertMessage: { message: "", title: "", isError: false },

            renderSize: 50
        }
    }


    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDmicRecorders")

        return miFireBase.database().ref(this.context.dbPath).child("MDmicRecorders")
    }

    getSettingsDbRef = () => {
        //CREO el puntero hacia configuracion
        return miFireBase.database().ref(this.context.dbPath).child("CwfSettings").child('autoMicRecorder')
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        this.dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        this.dbRef.on('value', snap => {
            console.log("****micRecordings dbRef.on VALUE****" + this.context.activeDevice.imei)

            let micRecordingsAux = []


            snap.forEach((snap) => {

                const rec = ({
                    key: snap.key,

                    duration: snap.val().duration,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView)
                })
                if (this.context.user.isDemoUsr) rec.unRead = false


                checkFileExistsFromFireStorage(miFireBase, rec, this.deleteObject)
                micRecordingsAux.push(rec)


            })
            sortArrayByFechaHora(micRecordingsAux)//solo se hace al principio



            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = micRecordingsAux[micRecordingsAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(micRecordingsAux, this.state.micRecordingsOrderRecentFirst)

            this.setState({
                micRecordings: micRecordingsAux,
                micRecordingsFiltered: micRecordingsAux,

                lastUpdate: lastUpdate,
                totalObjects: micRecordingsAux.length,
            }, () => this.handleCalendarChange())//Para que tenga en cuenta si hay filterDate en la URL


        })



        //CWF SETTINGS
        this.getSettingsDbRef().once('value', snap => {
            this.setState({ autoMicRecorder: snap.val() })
        });


    }


    componentWillUnmount() {
        this.dbRef.off()

        //vaciar fbTrash (elimino de firebase)
        this.state.fbTrash.map(objKey => {
            this.getOriginalDbRef().child(objKey).remove()
        })

    }


    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.state.fbTrash.push(objKey)//Debo eliminar todo junto al salir (Por tener referencia en timpo real, sino se vuelve a cargar todo por cada eliminacion)

            const originalArray = this.state.micRecordings
            const filteredArray = this.state.micRecordingsFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    micRecordings: originalArray,
                    micRecordingsAux: filteredArray,
                    totalObjects: originalArray.length
                })
            }

        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {

        //Aplica solo en la primera carga (cuando no habia ningun filtro fecha(osea la primer carga))
        const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
        if (dateFilterFromUrl && !this.state.filterDate) date = dateFilterFromUrl
        //------

        if (date) {

            let micRecordingsFilteredAux = []

            this.state.micRecordings.map(rec => {
                if (rec.fecha == format(date, 'yyyy-MM-dd'))
                    micRecordingsFilteredAux.push(rec)
            })


            sortArrayByRecentFirst(micRecordingsFilteredAux, this.state.micRecordingsOrderRecentFirst)

            this.setState({
                filterDate: date,
                micRecordingsFiltered: micRecordingsFilteredAux
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({
            micRecordingsFiltered: this.state.micRecordingsFiltered.reverse(),
            micRecordingsOrderRecentFirst: !this.state.micRecordingsOrderRecentFirst
        })
    }


    handleShowAllClick = () => {

        let micRecordingsAux = this.state.micRecordings//vuelvo a todos
        sortArrayByRecentFirst(micRecordingsAux, this.state.micRecordingsOrderRecentFirst)

        this.setState({
            micRecordingsFiltered: micRecordingsAux,
            filterDate: null,
        })
    }


    handelAutoMicRecorderClick = () => {
        this.getSettingsDbRef().set(!this.state.autoMicRecorder).then(snap => {
            this.setState({ autoMicRecorder: !this.state.autoMicRecorder })
            alert("Changes Saved Successfully")
        })
    }



    render() {
        const { t } = this.props; //función de traducción

        if (!this.state.micRecordings) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('MicRecorder.title')} </h3>
                        <MyLoader text={t('MicRecorder.loading')} />
                    </div>
                </div>
            )


        } else {


            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('MicRecorder.title')} </h3>
                        </div>
                    </div>


                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('MicRecorder.recordings')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ micRecordings: [], micRecordingsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />


                    <MicRecorderRealTime featureGranted={this.state.featureGranted} />


                    {/* SWITCH autoMicRecorder */}
                    <div className="row my-2 small">
                        <div className="col-12 text-center">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input " id="switch1" checked={this.state.autoMicRecorder} onChange={this.handelAutoMicRecorderClick} />
                                <label className="custom-control-label text-secondary pt-1" htmlFor="switch1">{t('MicRecorder.autoMic')}</label>
                            </div>
                        </div>
                    </div>
                    {/************/}


                    {/* FILTROS***** */}
                    {this.state.micRecordings.length > 0 && <div className="col-12 my-1 bg-dark  rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.micRecordings} />
                        <ButtonShowAll size1={this.state.micRecordings.length} size2={this.state.micRecordingsFiltered.length} handleShowAllClick={this.handleShowAllClick} />
                    </div>}
                    {/* *********** */}

                    <ShowNoItemsYet size={this.state.micRecordings.length} itemName={t('MicRecorder.recordings')} marginTop={20} />

                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.micRecordingsFiltered.length} filterName="date" showAll={this.handleShowAllClick} />


                    <div className="row">
                        <div className="col-12 mb-3">
                            {/* MIC RECORDINGs-------- */}

                            <ButtonOrderFirst length={this.state.micRecordingsFiltered.length} order={this.state.micRecordingsOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            {/* recorders */}
                            <ul className="list-group list-group-flush" >
                                {this.state.micRecordingsFiltered.slice(0, this.state.renderSize).map((rec, index) => {

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.micRecordingsFiltered} index={index} itemName={t('MicRecorder.recordings')} />
                                            <ShowRecording
                                                rec={rec}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}
                                            />
                                        </div>
                                    )
                                })}
                            </ul>

                            <ButtonGoUp arrayLength={this.state.micRecordingsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.micRecordingsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />



                        </div>
                    </div>

                </div>
            )

        }
    }

}
MicRecorder.contextType = MiContexto
MicRecorder = withTranslation()(MicRecorder);
export default withRouter(MicRecorder);



function ShowRecording(props) {

    const { t } = useTranslation();
    let history = useHistory();


    const [showControls, setShowControls] = useState(false)
    const [autoPlay, setAutoPlay] = useState(true)

    let { rec, featureGranted } = props

    //------
    let key = rec.key

    let duration = rec.duration
    let url = rec.url
    let fileName = rec.fileName

    let fecha = rec.fecha
    let hora = rec.hora
    let unRead = rec.unRead
    //-------

    if (duration == 0) duration = getRandomNumber(10, 100)


    const handlePlayClick = (value) => {
        if (featureGranted) {
            setShowControls(value)
        } else {
            alert(t('Buy.buyToListen'))
            history.push("/buy");
        }
    }


    // if (featureGranted) {
    //SE MUESTRA
    return (

        <Bounce >
            <li className={"list-group-item bg-dark"}>
                <div className="row">
                    <div className="col-12">

                        {unRead && <span className="badge badge-danger ">NEW!</span>}

                        {showControls ?
                            <div className="d-flex justify-content-center mb-2">
                                <ReactPlayer
                                    playing={autoPlay}
                                    url={url}
                                    controls={true}
                                    height='50px'
                                    volume={1}
                                    onPause={() => setAutoPlay(false)} onEnded={() => setAutoPlay(false)}
                                />
                            </div>
                            :
                            <div className="d-flex">
                                <button className="btn btn-success btn-sm mt-1 mr-2" style={{ width: "125px" }} onClick={() => handlePlayClick(true)}> <i className="fa fa-play mr-1" /> {t('MicRecorder.play')}</button>
                                <div className="mt-3 small text-success"><i className="fa fa-hourglass-end fa-1x " /> {duration} {t('MicRecorder.seconds')}</div>
                            </div>
                        }

                    </div>

                    <div className="col-12">
                        <div className="text-secondary small d-flex justify-content-end">
                            <ShowItemDate fecha={fecha} hora={hora} />
                            <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => { props.deleteObject(key); handlePlayClick(false); setAutoPlay(true) }} />
                        </div>
                    </div>

                </div>
            </li>
        </Bounce>
    )
}





class MicRecorderRealTime extends Component {

    constructor(props) {
        super(props)

        this.state = {

            recStatus: 0,
            currentRecordingFileUrl: null,
            startTimeCounter: false,

            featureGranted: props.featureGranted,

            alertMessage: { message: "", title: "", isError: false },
        }
    }

    getRealTimeDbRef = () => {
        //CREO el puntero realtime
        return miFireBase.database().ref(this.context.dbPath).child("realTimeResponse")
    }

    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("MDmicRecorders")
    }


    componentDidMount() {
        this.dbRef_RealTime = this.getRealTimeDbRef()
        this.dbRef_RealTime.remove();//elimino datos antiguos en la tabla

        this.dbRef_RealTime.on('value', snap => {
            console.log("****micRecordings dbRef_REALTIME.on VALUE****" + this.context.activeDevice.imei)

            if (snap.val()) { //Nuevos datos recibidos

                let comando = snap.val().comando;
                let respuesta = snap.val().respuesta;

                if (comando == "mic_ok") {
                    //GRABACION RECIBIDA EXITOSAMENTE!Ya tengo el file
                    this.setState({
                        recStatus: 0,
                        currentRecordingFileUrl: respuesta
                    })
                    UseWebServicePOST("operation=newCuota" + "&imei=" + this.context.activeDevice.imei + "&tipo=recordings")



                } else if (comando == "mic_error") {
                    //ERROR EN LA GRABACION
                    this.setState({
                        recStatus: 0,
                        alertMessage: { message: respuesta, title: "RECORDING ERROR ", isError: true, faIcon: "exclamation-circle" }
                    })
                    clearTimeout(this.timeOut);//apago timeout

                } else if (comando == "mic_start") {
                    //COMENZO LA GRABACION!!!!!!
                    this.setState({
                        recStatus: 1,
                        alertMessage: { message: "", title: "", isError: false }
                    })
                    clearTimeout(this.timeOut);//apago timeoutfa
                    this.timeCounterStart() //inicio cronometro
                    playAudio('rec')


                } else if (comando == "mic_up") {
                    //SUBIENDO AL SERVER LA GRABACION
                    this.setState({ recStatus: 4 })

                }

                console.log(snap.val());
            }



        })

    }

    componentWillUnmount() {
        this.dbRef_RealTime.off()
        if (this.state.recStatus == 1) sendPushMessageToDevice(this.context.activeDevice.token, "mic>stop")//Para que detenga grabacion (si la habia)
    }



    handleBtnRecordingEventsClick = () => {
        if (this.state.featureGranted) {

            let comando = ""
            switch (this.state.recStatus) {
                case 0:
                    comando = "mic>start"
                    //ESTADO INICIAL, PUEDO GRABAR
                    sendPushMessageToDevice(this.context.activeDevice.token, comando)
                    if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

                    this.timeOut = setTimeout(this.timeOutEvent, MAX_TIMEOUT_MS); //Enciendo el timeOut
                    this.setState({
                        recStatus: 2,
                        currentRecordingFileUrl: null,
                        alertMessage: { message: "", title: "", isError: false },
                    })
                    break;
                case 1:
                    comando = "mic>stop"
                    //GRABACION EN CURSO, PUEDO TERMINAR
                    sendPushMessageToDevice(this.context.activeDevice.token, comando)//Para que detenga grabacion
                    if (this.context.user.isDemoUsr) functionGetRandomRealTimeData(this.context.user.uid, this.context.activeDevice.imei, comando)

                    this.setState({
                        recStatus: 3,
                    }, () => this.timeCounterStop())
                default:
                    break;
            }
        } else {
            this.setState({ alertMessage: { message: " You must buy a subscription for this", title: "REAL TIME RECORDING: ", isError: true, faIcon: "exclamation-triangle" } })
        }


    }



    handleSaveFileClick = (save) => {

        if (this.state.currentRecordingFileUrl) {

            if (!this.context.user.isDemoUsr) {

                if (save) {
                    this.getOriginalDbRef().push({
                        url: this.state.currentRecordingFileUrl,
                        fecha: format(new Date(), 'yyyy-MM-dd'),
                        hora: format(new Date(), 'HH:mm:ss'),
                        fileName: "grab_" + format(new Date(), 'yyyy-MM-dd_HH:mm:ss'),
                        duration: this.recordingTimeSeconds
                    });
                    UseWebServicePOST("operation=newDataHistory" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID + "&newItems=" + 1)
                } else {
                    deleteFileFromFireStorage(miFireBase, this.state.currentRecordingFileUrl)
                }
            }

            this.setState({ currentRecordingFileUrl: null })
        }

    }


    //Time counter de la grabacion en curso
    timeCounterCallBack = () => {
        this.recordingTimeSeconds += 1
    }
    timeCounterStart = () => {
        this.setState({ startTimeCounter: true })
        this.recordingTimeSeconds = 0
    }
    timeCounterStop = () => {
        this.setState({ startTimeCounter: false })
    }
    //----------------


    timeOutEvent = () => {
        console.log("TIME OUT!!!")
        const { t } = this.props;

        this.setState({
            recStatus: 0,
            alertMessage: { message: t("TimeOut.message"), title: t("TimeOut.title"), isWarning: true, faIcon: "hourglass-end" }
        })

    }




    render() {
        const { t } = this.props; //función de traducción

        return (
            <div>
                {/* RECORDING SECTION */}
                <div className="row mt-3 ">
                    <div className="col-12">


                        <div className="d-flex justify-content-center py-0">

                            <div className="col-12 col-md-6 ">

                                {/* grabacion en curso */}
                                {this.state.recStatus == 1 &&
                                    <div className="mt-2 text-center">

                                        <div >
                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                controls={false}
                                                src="/images/micRecording.json"
                                                style={{ height: '80px ', width: '80px', padding: '0px' }}
                                            ></Player>

                                            <div className="mx-2 text-danger mt-0">
                                                {t('MicRecorder.recording')}
                                            </div>
                                            <span className='text-danger'><TimeCounter timeCounterCallBack={this.timeCounterCallBack} startTimeCounter={this.state.startTimeCounter} /></span>
                                        </div>

                                    </div>
                                }


                                {/* recibiendo archivo */}
                                {this.state.recStatus == 4 &&
                                    <div className='text-center my-3'><span className="mr-1 mt-1 text-danger"><div><i className='fa fa-spinner fa-spin mr-1 fa-3x mb-1' /></div>{t('MicRecorder.receiving')}</span><span className="mt-2"></span></div>
                                }


                                {!this.state.currentRecordingFileUrl &&
                                    <ButtonRecordingEvents recStatus={this.state.recStatus} onClick={this.handleBtnRecordingEventsClick} />
                                }

                                {/* Current file recording */}
                                <ShowCurrentRecording
                                    fileUrl={this.state.currentRecordingFileUrl}
                                    saveFileClick={this.handleSaveFileClick}
                                />
                            </div>

                        </div>



                    </div>

                    <div className="col-12">
                        <AlertMessage data={this.state.alertMessage} faIcon="exclamation-triangle" />
                    </div>



                </div>
                {/* ******************** */}




            </div>
        )
    }

}
MicRecorderRealTime.contextType = MiContexto
MicRecorderRealTime = withTranslation()(MicRecorderRealTime);



function ButtonRecordingEvents(props) {
    const { t } = useTranslation();

    const { recStatus } = props

    switch (recStatus) {
        case 0:
            return (<div className="text-center my-3"><button onClick={props.onClick} className="btn btn-sm btn-success greenShadow  text-dark"><i className="fa fa-microphone fa-2x mr-1 py-1" />{t('MicRecorder.startRec')}</button></div>)
        case 1:
            return (<div className="text-center my-3"><button onClick={props.onClick} className="btn btn-sm btn-danger  text-dark"><i className="fa fa-stop-circle fa-2x mr-1 py-1" />{t('MicRecorder.stop')}</button></div>)
        case 2:
            return (<div className='text-center my-3'><span className="mr-1 mt-1 text-danger"><div><i className='fa fa-spinner fa-spin mr-1 fa-3x mb-1' /></div>{t('MicRecorder.sendingRecRq')}</span><span className="mt-2"></span></div>)
        case 3:
            return (<div className='text-center my-3'><span className="mr-1 mt-1 text-danger"><div><i className='fa fa-spinner fa-spin mr-1 fa-3x mb-1' /></div>{t('MicRecorder.sendingStopRq')}</span><span className="mt-2"></span></div>)
        default:
            return (null)
    }
}



function ShowCurrentRecording(props) {

    const { t } = useTranslation();
    let { fileUrl } = props

    if (fileUrl) {
        //window.scrollTo({ behavior: 'smooth', top: 0 });
        return (
            <div className='my-4'>
                <Zoom>
                    <ReactPlayer
                        playing
                        url={fileUrl}
                        controls={true}
                        height='50px'
                        width='100%'
                        volume={1}
                    />
                </Zoom>

                <div className="p-2 d-flex justify-content-center">
                    <button className="btn btn-success mr-2" onClick={() => props.saveFileClick(true)}><i className="fa fa-check mr-1" />{t('MicRecorder.save')}</button>
                    <button className="btn btn-danger" onClick={() => props.saveFileClick(false)}><i className="fa fa-times mr-1" />{t('MicRecorder.discard')}</button>
                </div>

            </div>
        )

    } else {
        return null
    }

}


function TimeCounter(props) {
    const { t } = useTranslation();

    const { startTimeCounter } = props
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        if (startTimeCounter) {
            const interval = setInterval(() => {
                setSeconds(seconds => seconds + 1)
                props.timeCounterCallBack()
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setSeconds(0)
        }
    }, [startTimeCounter]);


    return (
        <span>{seconds} {t('MicRecorder.sec')} </span >
    )
}






