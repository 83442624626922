import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import {MyLoader, ShowFiltersResult, ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { removeFromMatriz, addToMatrizOfArrayItemsByDate, resizeNavBar, openUrl, checkFileExistsFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl,deleteFileFromFireStorage,fileExistsOnArray } from '../CommonFunctions'
import { ImageGallery2, ImageDialog } from './ImageGallery2'


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 17
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 30


class FolderFiles extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            groupByFolders: null,
            groupByExtension: null,

            pictures: null,
            picturesFiltered: [],
            picturesOrderRecentFirst: true,

            selectedPicture: "null",

            filterDate: null,
            filterFolderId: -1,
            filterExtension: -1,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
        //CREO el puntero

        // if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
        //     return miFireBase.database().ref(this.context.dbPathDummy).child("MDimgFromFolder")

        return miFireBase.database().ref(this.context.dbPath).child("MDimgFromFolder")//ORIGINAL PATH

    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****MDfolderFile pictures dbRef.once VALUE****" + this.context.activeDevice.imei)

            let picturesAux = []
            let groupByFoldersAux = []
            let groupByExtensionAux = []

            snap.forEach((snap) => {

                let pic = ({
                    granted: featureGranted,

                    key: snap.key,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    folderId: snap.val().folderId,
                    fileType: snap.val().fileType,//OBSOLETO (se reemplazo por folderId)
                    extension: snap.val()?.fileName?.split('.')?.pop(),
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0,
                    deleteObject: this.deleteObject
                })


                if (!pic.folderId) pic.folderId = generateFolderIdFromFileType(pic.fileType)


                checkFileExistsFromFireStorage(miFireBase, pic, this.deleteObject)

                if (!fileExistsOnArray(pic, picturesAux)) {//Como es acumulativo, debo chequear
                    picturesAux.push(pic)
                    this.addToGroupByFolders(pic.folderId, groupByFoldersAux)
                    this.addToGroupExtension(pic.extension, groupByExtensionAux)
                } else {
                    this.deleteObject(pic.key)
                }


            })
            sortArrayByFechaHora(picturesAux)//solo se hace al principio

            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = picturesAux[picturesAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------

            sortArrayByRecentFirst(picturesAux, this.state.picturesOrderRecentFirst)


            this.setState({
                pictures: picturesAux,
                picturesFiltered: picturesAux,

                groupByFolders: groupByFoldersAux,
                groupByExtension: groupByExtensionAux,

                lastUpdate: lastUpdate,
                totalObjects: picturesAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })



    }


    addToGroupByFolders = (folderId, currentGroup) => {

        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((s) => s.folderId).indexOf(folderId)

        if (pos < 0) {
            //Creo un nuevo elemento en group
            currentGroup.push({
                folderId: folderId,
                cant: 1,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cant += 1
        }

    }

    addToGroupExtension = (extension, currentGroup) => {
        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((s) => s.extension).indexOf(extension)
        if (pos < 0) {
            //Creo un nuevo elemento en group
            currentGroup.push({
                extension: extension,
                cant: 1,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cant += 1
        }
    }





    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.pictures
            const filteredArray = this.state.picturesFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    pictures: originalArray,
                    picturesFiltered: filteredArray,

                    totalObjects: originalArray.length,
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let picturesFilteredAux = []

            this.state.pictures.map(pic => {
                if (pic.fecha == format(date, 'yyyy-MM-dd'))
                    picturesFilteredAux.push(pic)
            })


            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterFolderId: -1,
                filterExtension: -1,
                picturesFiltered: picturesFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            picturesFiltered: this.state.picturesFiltered.reverse(),
            picturesOrderRecentFirst: !this.state.picturesOrderRecentFirst
        })

    }

    handleFolderChange = (event) => {

        const selectedFolder = event.target.value

        if (selectedFolder != -1) {

            let picturesFilteredAux = []
            this.state.pictures.map(pic => {
                if (pic.folderId == selectedFolder)
                    picturesFilteredAux.push(pic)
            })

            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)
            this.setState({
                filterFolderId: selectedFolder,
                filterDate: null,
                filterExtension: -1,
                picturesFiltered: picturesFilteredAux,
            })

        } else {
            this.handleShowAllClick()
        }
    }

    handleExtensionChange = (event) => {

        const selectedMediaType = event.target.value

        if (selectedMediaType != -1) {

            let picturesFilteredAux = []
            this.state.pictures.map(pic => {
                if (pic.extension == selectedMediaType)
                    picturesFilteredAux.push(pic)
            })

            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)
            this.setState({
                filterExtension: selectedMediaType,
                filterFolderId: -1,
                filterDate: null,
                picturesFiltered: picturesFilteredAux,
            })

        } else {
            this.handleShowAllClick()
        }

    }


    handleShowAllClick = () => {

        this.setState({
            filterDate: null,
            filterFolderId: -1,
            filterExtension: -1,
            picturesFiltered: this.state.pictures,
        })
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            openUrl(item.url)
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }
    handlePictureDelete = (item) => {
        window.$('#ShowImageDialog').modal('hide')
        if (this.state.featureGranted) {
            this.deleteObject(item.key)
        } else {
            this.props.history.push('/buy');
        }
    }
    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }



    render() {
        const { t } = this.props;

        if (!this.state.pictures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>GALERÍA</h3>
                        <MyLoader text={"Cargando Galería..."}/>
                    </div>
                </div>
            )


        } else {

            if (this.state.pictures.length == 0) return <ShowNoItemsYet size={this.state.pictures.length} itemName='pictures' />


            return (
                <div onClick={() => resizeNavBar()}>

                    <ImageDialog image={this.state.selectedPicture} folderName={getFolderName(this.state.selectedPicture.folderId)} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>GALERÍA</h3>
                        </div>
                    </div>


                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title=" pictures" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ pictures: [], picturesFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />




                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark  rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.pictures} />

                        <SelectFolderGroup group={this.state.groupByFolders} value={this.state.filterFolderId} handleOnChange={this.handleFolderChange} featureGranted={this.state.featureGranted} />
                        <SelectExtension group={this.state.groupByExtension} value={this.state.filterExtension} handleOnChange={this.handleExtensionChange} featureGranted={this.state.featureGranted} />
                        <ButtonShowAll size1={this.state.pictures} size2={this.state.picturesFiltered} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.picturesFiltered.length} filterName='date' showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={getFolderName(this.state.filterFolderId)} filterResultSize={this.state.picturesFiltered.length} filterName='folder' showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterExtension} filterResultSize={this.state.picturesFiltered.length} filterName='extension' showAll={this.handleShowAllClick} />


                    <div className="row">
                        <div className="col-12 mb-3">

                            <React.Fragment>
                                {this.state.picturesFiltered.length > 0 &&
                                    <div>
                                        <ButtonOrderFirst length={this.state.picturesFiltered.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />
                                        <ImageGallery2 items={this.state.picturesFiltered.slice(0, this.state.renderSize)} handleThumbClick={this.handleThumbClick} />
                                    </div>
                                }
                            </React.Fragment>


                            <ButtonGoUp arrayLength={this.state.picturesFiltered.length} maxLength={20} />
                            <ButtonShowMore arrayLength={this.state.picturesFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />


                        </div>
                    </div>

                </div>
            )

        }
    }
}
FolderFiles.contextType = MiContexto
FolderFiles = withTranslation()(FolderFiles);
export default withRouter(FolderFiles);



function SelectFolderGroup(props) {
    const { t } = useTranslation();

    const { group, value, featureGranted } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-folder-open-o fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="SelectFolderGroup" onChange={props.handleOnChange} >

                <option value={-1}>Todas las carpetas</option>
                {group.map((group, index) => {
                    let folderId = group.folderId

                    if (!featureGranted) folderId = folderId.substring(0, 2) + t('WifiHistory.buySub')

                    return (
                        <option key={index} value={group.folderId}>{getFolderName(folderId)}  ({group.cant}) </option>
                    )
                })}

            </select>
        </div>
    )
}

function SelectExtension(props) {
    const { t } = useTranslation();

    const { group, value, featureGranted } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-file-o fa-1x px-1"></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="SelectMediaType" onChange={props.handleOnChange} >

                <option value={-1}>Todos los tipos</option>
                {group.map((group, index) => {
                    let extension = group.extension

                    if (!featureGranted) extension = extension.substring(0, 2) + t('WifiHistory.buySub')

                    return (
                        <option key={index} value={group.extension}>{extension}  ({group.cant}) </option>
                    )
                })}

            </select>
        </div>
    )
}



//Funcion auxiliar para versiones Catwatchful <=16
function generateFolderIdFromFileType(fileType) {
    // CAMERA_IMAGE = 1;
    // WTSP_RECEIVED_IMAGE = 2;
    // WTSP_SENT_IMAGE = 3;
    // FB_IMAGE = 4;
    // SCREENSHOOT_IMAGE = 5;
    // DOWNLOAD_IMAGE = 6;
    // WTSP_AUDIO = 7;
    if (fileType == 1) return "folderCam"
    if (fileType == 2) return "wtspImgRec"
    if (fileType == 3) return "wtspImgSent"
    if (fileType == 4) return "folderFb"
    if (fileType == 5) return "folderScreen"
    if (fileType == 6) return "folderDownload"
    if (fileType == 7) return "wtspAudioRec"
    return "folderCam"
}

function getFolderName(folderId) {
    //const { t } = this.props; //función de traducción

    if (folderId == "folderCam") return "Camara"
    if (folderId == "folderFb") return "Facebook"
    if (folderId == "folderScreen") return "Capturas de Pantalla"
    if (folderId == "folderDownload") return "Descargas"
    if (folderId == "wtspImgRec") return "Whatsapp Imágenes Recibidas"
    if (folderId == "wtspImgSent") return "Whatsapp Imágenes Enviadas"
    if (folderId == "wtspVidRec") return "Whatsapp Videos Recibidos"
    if (folderId == "wtspVidSent") return "Whatsapp Videos Enviados"
    if (folderId == "wtspAudioRec") return "Whatsapp Audios Recibidos"
    if (folderId == "wtspAudioSent") return "Whatsapp Audios Enviados"
    if (folderId == "wtspDocsRec") return "Whatsapp Documentos Recibidos"
    if (folderId == "wtspDocsSent") return "Whatsapp Documentos Enviados"
    if (folderId == "wtspVoice") return "Whatsapp Notas de Voz"
    if (folderId == "instaImg") return "Instagram"


}


