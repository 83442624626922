import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---
import { HashRouter, Link } from "react-router-dom";

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import { sendPushMessageToDevice } from '../CloudFunctions'
import miFireBase from '../config/config'


import { MyLoader, ShowFiltersResult, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, isToday, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, isValidDate } from '../CommonFunctions'


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

//Traduccion
import { withTranslation, useTranslation } from 'react-i18next';


import Map from './Map.jsx';


const FEATURE_ID = 8
const LIMIT_TO_LAST = 2000


class GpsHistory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            gpsHistory: null,
            gpsHistoryFiltered: [],
            gpsHistoryOrderRecentFirst: true,
            displayAllLocations: false,

            filterDate: null,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,


            currentLocation: null
        }

        this.selectedLocationRef = React.createRef()

    }



    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("coordenadas")

        return miFireBase.database().ref(this.context.dbPath).child("coordenadas")
    }




    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        this.dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        this.dbRef.on('value', snap => {
            console.log("****GpsHistory dbRef.on VALUE****" + this.context.activeDevice.imei)

            let gpsHistoryAux = []

            snap.forEach((snap) => {

                const gps = ({
                    key: snap.key,

                    latitud: snap.val().latitud,
                    longitud: snap.val().longitud,

                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })


                gpsHistoryAux.push(gps)//No se debe verificar si ya existe, porque SOLO se van mandando las nuevas(no el historial del telefono)

            })
            sortArrayByFechaHora(gpsHistoryAux)//solo se hace al principio


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = gpsHistoryAux[gpsHistoryAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(gpsHistoryAux, this.state.gpsHistoryOrderRecentFirst)

            this.setState({
                gpsHistory: gpsHistoryAux,
                gpsHistoryFiltered: gpsHistoryAux,

                lastUpdate: lastUpdate,
                totalObjects: gpsHistoryAux.length,
            }, () => lastObject && isValidDate(lastObject.fecha) && this.handleCalendarChange(new Date(lastObject.fecha + " " + lastObject.hora))//por defecto selecciono la ultima fecha que hubo registro de coordenadas
            )


        })



        sendPushMessageToDevice(this.context.activeDevice.token, "gps")//Para que actualice last location en el dispositivo
    }


    componentWillUnmount() {
        this.dbRef.off()
    }



    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.gpsHistory
            const filteredArray = this.state.gpsHistoryFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    gpsHistory: originalArray,
                    gpsHistoryFiltered: filteredArray,
                    totalObjects: originalArray.length
                })
            }
        }
    }


    handleCalendarChange = (date) => {

        //Aplica solo en la primera carga (cuando no habia ningun filtro fecha(osea la primer carga))
        const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
        if (dateFilterFromUrl && !this.state.filterDate) date = dateFilterFromUrl
        //------


        if (date) {

            let gpsHistoryFilteredAux = []

            this.state.gpsHistory.map(gps => {
                if (isValidDate(gps.fecha) && gps.fecha == format(date, 'yyyy-MM-dd'))
                    gpsHistoryFilteredAux.push(gps)
            })
            sortArrayByRecentFirst(gpsHistoryFilteredAux, this.state.gpsHistoryOrderRecentFirst)


            let lastLocationForThisDate = gpsHistoryFilteredAux[0]

            this.setState({
                filterDate: date,
                gpsHistoryFiltered: gpsHistoryFilteredAux,
                currentLocation: lastLocationForThisDate
            })

        }
    }

    handleOrderChange = () => {

        this.setState({
            gpsHistoryFiltered: this.state.gpsHistoryFiltered.reverse(),
            gpsHistoryOrderRecentFirst: !this.state.gpsHistoryOrderRecentFirst
        })
    }





    handleSelectLocationChange = (event) => {
        const elem = this.state.gpsHistory[this.state.gpsHistory.map((g) => g.key).indexOf(event.target.value)]


        if (elem) {
            this.setState({
                currentLocation: elem,
                displayAllLocations: false
            }, () => window.scrollTo({ behavior: 'smooth', top: this.selectedLocationRef.current.offsetTop }))
        }

    }

    handleDisplayAllLocations = () => {
        this.setState({
            displayAllLocations: !this.state.displayAllLocations,
            currentLocation: null
        })
        document.querySelector("#selectLocationTime").value = '-1'
    }



    render() {

        const { t } = this.props; //función de traducción


        if (!this.state.gpsHistory) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t("Gps.title")} </h3>
                        <MyLoader text={t('Gps.loading')} />
                    </div>
                </div>
            )


        } else {

            if (this.state.gpsHistory.length == 0) return  <ShowNoItemsYet size={this.state.gpsHistory.length} itemName={t("Gps.locations")} />


            return (

                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t("Gps.title")} </h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t("Gps.locations")} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ gpsHistory: [], gpsHistoryFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />
                   


                    <div className="col-12 my-1 bg-dark rounded-lg p-2">
                        <div >
                            <SelectDate selected={this.state?.filterDate} array={this.state.gpsHistory} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} />
                        </div>
                    </div>
                    {/* *********** */}

                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.gpsHistoryFiltered.length} filterName="date" />


                    {/* SELECT LOCATION TIME-------- */}
                    <div className="mt-3">

                        <select value={this.state.currentLocation?.key} className="form-control miSelect " id="selectLocationTime" onChange={this.handleSelectLocationChange} >
                            <option value={-1} >{t("Gps.selectTime")}</option>
                            {this.state.gpsHistoryFiltered.map((gps, index) => {

                                if (isValidDate(gps.fecha)) {
                                    return <option key={index} value={gps.key}>{moment(gps.fecha + " " + gps.hora).format("HH:mm A")}</option>
                                } else {
                                    return <option key={index} value={gps.key}>{gps.fecha + " " + gps.hora}</option>
                                }
                            })}
                        </select>
                    </div>

                    <div className="form-check form-check-inline  mt-1 small">
                        <input className="form-check-input ml-2" type="checkbox" id="inlineCheckbox1" value="option1" checked={this.state.displayAllLocations} onChange={this.handleDisplayAllLocations} /> <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Gps.showAllMoves")}</label>
                    </div>




                    {/* SELECTED LOCATION */}
                    <div className="row">
                        <div className="col-12" ref={this.selectedLocationRef}>


                            {this.state.displayAllLocations ?
                                <ShowSelectedLocation
                                    locations={this.state.gpsHistoryFiltered}
                                    featureGranted={this.state.featureGranted}
                                    history={this.props.history}
                                    deleteObject={this.deleteObject}
                                />
                                :
                                <ShowSelectedLocation
                                    location={this.state.currentLocation}
                                    featureGranted={this.state.featureGranted}
                                    history={this.props.history}
                                    deleteObject={this.deleteObject}
                                />
                            }


                        </div>
                    </div>


                    {/* ------------ */}

                </div>
            )

        }
    }
}
GpsHistory.contextType = MiContexto
GpsHistory = withTranslation()(GpsHistory);
export default withRouter(GpsHistory);



function ShowSelectedLocation(props) {

    const { location, locations, featureGranted, history } = props

    const { t } = useTranslation(); //función de traducción


    if (location || locations) {

        let key = location?.key
        let latitud = location?.latitud
        let longitud = location?.longitud

        let fecha = location?.fecha
        let hora = location?.hora
        let unRead = location?.unRead


        // if (featureGranted) {

        const handleViewOnGoogle = () => {
            if (featureGranted) {
                window.open("https://www.google.com/maps?q=" + latitud + "," + longitud, "_blank")
            } else {
                history.push('/buy');
            }
        }


        let blur = ""
        if (!featureGranted) blur = "divBlur"

        //SE MUESTRA
        return (

            <Bounce >

                <div className={`p-0 mb-0 d-flex justify-content-center mt-1 ${blur}`} >
                    {locations ?
                        <Map locations={locations} zoom={13} />
                        :
                        <Map lat={latitud} lng={longitud} zoom={16} fecha={fecha} hora={hora} />
                    }

                </div>


                <div className=" rounded-lg p-2  bg-dark rounded-lg">
                    {unRead && <div><span className="badge badge-danger mb-1 ">{t("New")}</span></div>}

                    <div className="d-flex mb-1 small">
                        <i className="fa fa-map-marker fa-1x text-warning fa-2x mx-1" />

                        <div className='d-flex mt-2'>
                            <span className="text-light"><span className="mr-2">Lat: <span className={blur}>{latitud}</span> </span>Long: <span className={blur}>{longitud}</span></span>
                            <i className=" ml-2 fa fa-trash-o text-danger ml-3 mt-1" onClick={() => props.deleteObject(key)} />
                        </div>
                    </div>
                    <button onClick={handleViewOnGoogle} className="btn btn-outline-warning btn-block btn-sm ">{t("Gps.viewOnGoogle")} </button>
                </div>

            </Bounce >

        )


    } else {
        return null
    }
}





