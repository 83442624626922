import React, { useEffect, useState, useContext, useRef } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import miFireBase from '../config/config'

import MiContexto from '../MiContexto'
import { resizeNavBar } from '../CommonFunctions'
import { MyLoader } from '../CommonComponents';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

import QRCode from "react-qr-code";

import { WpChats, WpConversation } from './Messagges/SkinWpp'
import moment from 'moment';

import Confetti from 'react-confetti'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import Countdown, { zeroPad } from 'react-countdown';
import { set } from 'lodash';

const FEATURE_ID = 30


const URL_BACKEND_WTSPWEB = "https://wtspclone.fly.dev"
//const URL_BACKEND_WTSPWEB = "http://localhost:8080"
const UPDATE_DELAY = 60 //minutes




export default function WtspClone(props) {

    const history = useHistory();
    const { t } = useTranslation();
    const { activeDevice, user } = useContext(MiContexto)

    const [loaddingFirebaseDB, setLoadingFirebaseDB] = useState(true)

    const [loadingWtspSession, setLoadingWtspSession] = useState(false)
    const [QR, setQR] = useState('')

    const [status, setStatus] = useState('')

    const [dateLogin, setDateLogin] = useState(null)
    const [chats, setChats] = useState([])
    const [currentChat, setCurrentChat] = useState([])

    const [showConfetti, setShowConfetti] = useState(false)

    const [warning, setWarning] = useState('')

    const [featureGranted, setFeatureGranted] = useState(false)

    //let interval_QR_scan_success = useRef(null)//para esperar el qr scan exitoso


    const { imei } = activeDevice


    useEffect(() => {
        resizeNavBar()



        let featureGranted = (activeDevice.statusCode == 1) && activeDevice.subscription.features[FEATURE_ID]
        setFeatureGranted(featureGranted)
        console.log("featureGranted", featureGranted)

        miFireBase.database().ref(`wtspClone/${imei}/chats`).on('value', (snapshot) => {
            setLoadingFirebaseDB(false)
            if (snapshot.val()) {
                console.log("snapshot.val(CHATS)", snapshot.val())
                setChats(snapshot.val())
            } else {
                window.$('#explicacionModal').modal()
            }
        })

        miFireBase.database().ref(`wtspClone/${imei}/session`).on('value', (snapshot) => {
            if (snapshot.val()) {
                console.log("snapshot.val(SESSION)", snapshot.val())

                let { qr, dateLogin, loadStatus } = snapshot.val()

                setQR(qr)
                setDateLogin(dateLogin)
                setStatus(loadStatus)

                if (!loadStatus) {

                    setLoadingWtspSession(false)
                    if (moment().diff(dateLogin, 'minutes') >= UPDATE_DELAY) loadWtspSession()

                } else {
                    if (moment().diff(loadStatus.date, 'minutes') >= 10) loadWtspSession() //Por si se colgo el proceso de carga
                    setLoadingWtspSession(true)
                }




                const dateQr = new Date(snapshot.val().dateQr)
                if (moment().diff(dateQr, 'minutes') >= 1) setQR(null)

                if (!snapshot.val().dateLogin) {
                    setWarning(t("WhatsapClone.noSession"))
                } else {
                    setWarning(null)
                }
            }
        })

        return () => {
            miFireBase.database().ref(`wtspClone/${imei}/chats`).off()
            miFireBase.database().ref(`wtspClone/${imei}/session`).off()
            window.$('#explicacionModal').modal('hide')
        }


    }, [])






    const loadWtspSession = async () => {

        if (!user.isDemoUsr) {

            console.log("LOADWTSPSESSIONNN!!!!!!!!!!!")

            setLoadingWtspSession(true)
            await axios.get(`${URL_BACKEND_WTSPWEB}/device/${imei}`)
                .then(response => {
                    console.log(response)

                    if (response.data.qr)
                        setQR(response.data.qr)
                })
                .catch(e => {
                    console.log("Error", e)
                });
        } else {
            console.log("DEMO USER")
            setWarning(t("WhatsapClone.demoUser"))

        }


    }


    const handleChatChange = async (chatID) => {
        console.log("handleChatChange", chatID)

        let chat = chats.find(c => c.chatID == chatID)
        setCurrentChat(chat)

        window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight })
    }
    const deleteChat = async (chat) => {
        console.log("deleteChat", chat)
    }
    const deleteObject = async (object) => {
        console.log("deleteObject", object)
    }



    const countdownRender = () => {
        return ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                return null
            } else {
                return <div style={{ background: "#008069" }} className='font-weight-bold p-2 rounded h6 my-2'>{t("WhatsapClone.nextUpdate")} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</div>
            }
        }
    }


    if (loaddingFirebaseDB) {
        return (
            <div onClick={() => resizeNavBar()}>
                <h3>{t("WhatsapClone.title")}</h3>
                <MyLoader text={t('WhatsapClone.conectando')}/>
            </div>
        )
    }


    if (!featureGranted) {
        return (
            <div onClick={() => resizeNavBar()}>
                <h3>{t("WhatsapClone.title")}</h3>
                <ExplicacionModal />

                <div className="container  text-center mt-4">
                    <div className='alert alert-warning' onClick={() => history.push('/buy')} ><i className='fa fa-warning' /> {t("WhatsapClone.noSuscription")}</div>

                    <div className='divBlur'>
                        {chats.length > 0 ?
                            <WpChats
                                chats={chats}
                                handleChatChange={handleChatChange}
                                deleteChat={deleteChat}
                                totalObjectsUnread={null}
                                lastUpdate={dateLogin}
                            />
                            :
                            <div className='text-center my-4'>
                                <img src="./images/demochat.jpg" alt="" className='img-fluid' style={{ maxWidth: "360px" }} />
                            </div>
                        }
                    </div>

                </div>
            </div>
        )
    }



    return (
        <div onClick={() => resizeNavBar()}>
            <h3>{t("WhatsapClone.title")}</h3>
            <ExplicacionModal />

            {showConfetti && <Confetti recycle={false} />}
            {warning && <div className='alert alert-warning'><i className='fa fa-warning mr-1' />{warning}</div>}
            {dateLogin && <div>{t("WhatsapClone.lastUpdate")} <span style={{ color: "#008069" }}> <Moment format="dddd Do, MMMM HH:mm">{dateLogin}</Moment> </span></div>}

            {loadingWtspSession && <div className='text-warning my-2'><i className='fa fa-spinner fa-spin mr-1' />{t("WhatsapClone.updating")} <span className='font-weight-bold'>{status?.event}</span></div>}


            {dateLogin && <Countdown date={new Date((new Date(dateLogin)).getTime() + UPDATE_DELAY * 60000)} daysInHours={true} renderer={countdownRender()} />}

            {!dateLogin &&
                <div>
                    {!loadingWtspSession ?
                        <div>
                            {!QR &&
                                <div>
                                    <button className='btn btn-primary btn-block' onClick={loadWtspSession} >{t("WhatsapClone.obtenerQr")}</button>
                                    <Instrucciones complete={true} />
                                </div>}
                        </div>
                        :
                        <div>
                            <button className='btn btn-primary btn-block disabled'><i className='fa fa-spinner fa-spin' /> {t("WhatsapClone.obteniendoQr")}</button>
                            <Instrucciones complete={true} />
                        </div>
                    }
                </div>
            }



            {QR &&
                <div >
                    <h5 className='text-center'>{t("WhatsapClone.escanQr")}</h5>
                    <Instrucciones complete={false} />
                    <div className="mt-4" style={{ height: "auto", margin: "0 auto", maxWidth: 350, width: "100%", border: "8px solid #fff" }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={QR}
                            viewBox={`0 0 256 256`}
                        />
                    </div>

                    <div className='d-flex justify-content-center p-4 '>
                        <CountdownCircleTimer
                            isPlaying
                            duration={60}
                            initialRemainingTime={60}
                            colors={['#28a745', '#F7B801', '#A30000', '#A30000']} //green, yellow, red
                            size={140}
                            strokeWidth={20}
                            colorsTime={[30, 10, 10, 10]}>
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>


                </div>
            }

            {!QR &&

                <div>
                    {chats.length > 0 &&
                        <div className='my-2'>

                            <WpChats
                                chats={chats}
                                handleChatChange={handleChatChange}
                                deleteChat={deleteChat}
                                totalObjectsUnread={null}
                                lastUpdate={dateLogin}
                            />
                        </div>
                    }
                    {currentChat?.conversation?.length > 0 &&
                        <div >
                            <WpConversation
                                chat={currentChat}
                                messages={currentChat.conversation.sort((a, b) => a?.timestamp - b?.timestamp)}
                                filter={null}
                                featureGranted={true}
                                deleteChat={deleteChat}
                                deleteObject={deleteObject}
                            />
                        </div>
                    }
                </div>
            }



        </div>
    )

    function ExplicacionModal() {

        const { t } = useTranslation();

        let iconStyle = { fontSize: "14px", color: "#008069" }
        return (
            <div className="modal fade text-dark" id="explicacionModal" tabIndex="-1" role="dialog" aria-labelledby="explicacionModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "#008069" }}>
                            <h4 className="modal-title text-uppercase" id="exampleModalLabel" style={{ color: "#FFF" }}><i className="fa fa-whatsapp mr-1 fa-2x" />{t("WhatsapClone.modalTitle")}  </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-left " style={{fontSize:"0.7em"}}>
                            <div className='mb-3 h5'>{t("WhatsapClone.comoFuncionaTitle")}</div>
                            <div className='mb-3'><i className='fa fa-circle mr-1' style={iconStyle} />{t("WhatsapClone.comoFuncionaTip1")}.</div>
                            <div className='mb-3'><i className='fa fa-circle mr-1' style={iconStyle} />{t("WhatsapClone.comoFuncionaTip2")}.</div>
                            <div className='mb-3'><i className='fa fa-circle mr-1' style={iconStyle} />{t("WhatsapClone.comoFuncionaTip3")}.</div>
                            <div className='mb-3'><i className='fa fa-circle mr-1' style={iconStyle} />{t("WhatsapClone.comoFuncionaTip4")}.</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" >{t("WhatsapClone.btnContinuar")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function Instrucciones(props) {
        const imagesEsp = [
            { url: "images/ww1.png" },
            { url: "images/ww2.png" },
            { url: "images/ww3.png" },
            { url: "images/ww4.png" }
        ];
        const imagesEng = [
            { url: "images/ww1_en.png" },
            { url: "images/ww2_en.png" },
            { url: "images/ww3_en.png" },
            { url: "images/ww4_en.png" }
        ];

        let images = imagesEsp
        const browserLanguage = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language//Detecto IDIOMA del navegador
        if (browserLanguage.includes("en")) images = imagesEng

        let { complete } = props
        const Steps = () => {
            return (
                <div className=' ml-2'>
                    {complete && <div className='mb-1 font-weight-bold'>{t("WhatsapClone.modeOfUse")}</div>}
                    <div className='mb-1'><span className='font-weight-bold text-warning'>1-</span> {t("WhatsapClone.step1")}</div>
                    <div className='mb-1'><span className='font-weight-bold text-warning'>2-</span> {t("WhatsapClone.step2")}</div>
                    <div className='mb-1'><span className='font-weight-bold text-warning'>3-</span> {t("WhatsapClone.step3")}</div>
                    <div className='mb-1'><span className='font-weight-bold text-warning'>4-</span> {t("WhatsapClone.step4")}</div>
                    <div className='mb-1'><span className='font-weight-bold text-warning'>5-</span> {t("WhatsapClone.step5")}</div>
                </div>
            )
        }


        return (
            <div>
                {complete ?
                    <div className='d-flex justify-content-center my-2 rounded py-4' style={{ background: "#000", fontSize: "12px" }}>
                        <div>
                            <Steps />
                            <hr className='bg-secondary' />
                            <div className='d-flex justify-content-center'>
                                <SimpleImageSlider
                                    width={200}
                                    height={400}
                                    images={images}
                                    showBullets={true}
                                    showNavs={false}
                                    autoPlay={true}
                                />
                            </div>

                        </div>


                    </div>
                    :
                    <div className='my-1 d-flex justify-content-center rounded py-1' style={{ background: "#000", fontSize: "13px" }}>
                        <Steps />
                    </div>
                }
            </div>

        )



    }


}

